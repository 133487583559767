import Header from './partial/header'
import Footer from './partial/footer'

export default function Layout({ children, isPending }) {
    return (
        <div className="layout">
            <Header style={{ 
                opacity: isPending ? 0.7 : 1
            }} />
            {children}
            <Footer />
        </div>
    );
}
