import axios from 'axios'
import cookieClient from 'react-cookie'


var apiBaseURL = 'https://api.totilipotili.com'
// if (window.location.href.includes("http://localhost:3000")) {
//     apiBaseURL = 'http://localhost:3000'
// }

const requestOptionsGet = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // Authorization: "Bearer " + accessToken
  },
}

function getNoCacheParams(isFirstParams: any) {
  let startChar = isFirstParams ? '?' : '&'
  return startChar + 'rnd=' + new Date().getTime()
}

// Kayıt Ol
export async function signUp(first_name = '', last_name = '', email = '', phoneNumber = '', childBirthDate = '', password = '', password_repeat = '', city = '') {
  const resp = await axios
    .post(apiBaseURL + '/users/signup', {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phoneNumber: phoneNumber,
      childBirthDate: childBirthDate,
      password: password,
      password_repeat: password_repeat,
      residence_city: city,
    })
  return resp.data;
}

// Giriş yap
export const signIn = async function (email = '', password = '') {
  const resp = await axios.post(apiBaseURL + '/users/login', {
    email: email,
    password: password
  });
  return resp.data;
};

// User Detail
export const getUserDetail = async function (id = '', accessToken = '') {
  const resp = await axios.get(apiBaseURL + '/users/details/' + id, {
    headers: { "Authorization": "Bearer " + accessToken }
  });
  return resp.data;
};


// Kategoriler
export async function getCategories() {
  try {
    const resp = await axios.get(apiBaseURL + `/categories`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Slider
export async function getSliders() {
  try {
    const resp = await axios.get(apiBaseURL + `/sliders`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Events
export async function getEvents(page = '', size = '', city = '', county = '', ageInterval = '', sorting = '', category = '', search = '') {
  try {
    const resp = await axios.get(apiBaseURL + `/events?page=${page}&pageSize=${size}&city=${city}&county=${county}&ageInterval=${ageInterval}&sorting=${sorting}&category=${category}&search=${search}`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// User's Events  
export const getOrders = async function(page = 1, pageSize = 100, accessToken = ''){
  try{
      const resp = await axios.get(apiBaseURL +`/orders?page=${page}&pageSize=${pageSize}`,{
          headers:{"Authorization": "Bearer " + accessToken}
      });
      console.log(resp.data)
      return resp.data;
  }catch(err){
    // Authotization hatası dönerse localstorage'daki token'ı sil 
      // if(err.response.status === 401){
      //     localStorage.removeItem("access-token");
      //     localStorage.removeItem("user");

      // }
  }
};

export async function blogList() {
  try {
    const resp = await axios.get(apiBaseURL + `/blogCategories`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Events
export async function blogCategories(category) {
  try {
    const resp = await axios.get(apiBaseURL + `/blogs?category=` + category, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Event Detail
export async function getEventById(id) {
  try {
    const resp = await axios.get(apiBaseURL + `/events/${id}`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Blogs
export async function getBlogs() {
  try {
    const resp = await axios.get(apiBaseURL + `/blogs`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Verify User
export async function verifyUser(email = '', verification_code = '') {
  const data = axios
    .post(apiBaseURL + '/users/verifyUser', {
      email: email,
      verification_code: verification_code,
    })
    .then(function (response) {
      // console.log(response)
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

// Event id
export async function getCompanyById(id) {
  try {
    const resp = await axios.get(apiBaseURL + `/event/${id}`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Payment
export async function postPayment(nameSurname = '', invoiceNameSurname = '', invoiceCity = '', invoiceDistrict = '', invoiceAdress = '', invoiceId = '', paymentNameSurname = '', paymentCardNumber = '', paymentExpireDate = '', paymentCvc = '', eventId = '') {
  const data = axios
    .post(apiBaseURL + '/payment/makePayment', {
      nameSurname: nameSurname,
      invoiceNameSurname: invoiceNameSurname,
      invoiceCity: invoiceCity,
      invoiceDistrict: invoiceDistrict,
      invoiceAdress: invoiceAdress,
      invoiceId: invoiceId,
      paymentNameSurname: paymentNameSurname,
      paymentCardNumber: paymentCardNumber,
      paymentExpireDate: paymentExpireDate,
      paymentCvc: paymentCvc,
      eventId: eventId
    })
    .then(function (response) {
      // console.log(response)
    })
    .catch(function (error) {
      console.log(error)
    })

  return data
}


// Blog Detail
export async function getBlogById(id) {
  try {
    const resp = await axios.get(apiBaseURL + `/blogs/${id}`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}


// Change Password
export const changePassword = async function (userId = '', oldPassword = '', newPassword = '', accessToken = '') {
  const resp = await axios.post(apiBaseURL + '/users/changePassword', {
    id: userId,
    oldPassword: oldPassword,
    newPassword: newPassword,
  }, { headers: { "Authorization": "Bearer " + accessToken } }
  );
  return resp.data;
};


// Get City
export async function getAllCities() {
  try {
    const resp = await axios.get(apiBaseURL + `/getAllCities`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// Etkinlik olan iller
export async function getCities() {
  try {
    const resp = await axios.get(apiBaseURL + `/getCities`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}

// İlçeler
export async function getCounties(city) {
  try {
    const resp = await axios.get(apiBaseURL + `/getCounties/${city}`, {})
    // console.log(resp.data)
    return resp.data
  } catch (err) {
    console.log(err)
  }
}



// Şifremi Unuttum
export async function forgetPassword(email = '') {
  const resp = await axios
    .post(apiBaseURL + '/users/forgotPassword', {
      email: email,
    })
  return resp.data;
}


// Payment


export const makePayment = async function (nameSurname, nameSurnameReceipt, address, city, county, phone, email, eventId, amount, number, tckn, taxNo, taxAdministration, companyName, accessToken) {
  try {
    const resp = await axios.post(apiBaseURL + `/payment`,
      {
        nameSurname: nameSurname,
        nameSurnameReceipt: nameSurnameReceipt,
        address: address,
        city: city,
        county: county,
        phone: phone,
        email: email,
        eventId: eventId,
        amount: amount,
        number: number, // number of tickets 
        tckn: tckn,
        taxNo: taxNo,
        taxAdministration: taxAdministration,
        companyName: companyName
      },
      {
        headers: { "Authorization": "Bearer " + accessToken }
      });

    console.log(resp.data)
    return resp.data;
  } catch (err) {

  }
};

export const checkPaymentStatus = async function (orderId, accessToken) {
  try {
    const resp = await axios.get(apiBaseURL + `/payment/isPaymentCompleted/${orderId}`,
      {
        headers: { "Authorization": "Bearer " + accessToken }
      });
    return resp.data;
  } catch (err) {

  }
}

export const getDiscount = async function (orderId, accessToken) {
  try {
    const resp = await axios.get(apiBaseURL + `/payment/discount/${orderId}`,
      {
        headers: { "Authorization": "Bearer " + accessToken }
      });
    return resp.data;
  } catch (err) {

  }
}


// Blog Detail
export async function getVendorById(id) {
  try {
    const resp = await axios.get(apiBaseURL + `/users/vendor/${id}`, {})
    return resp.data
  } catch (err) {
    console.log(err)
  }
}


// İş Ortağı Ol
export const businessPartner = async function (nameSurname = '', companyName = '', email = '', phone = '') {
  try {
    const resp = await axios.post(apiBaseURL+`/businessPartner`,
      {
        nameSurname: nameSurname,
        companyName: companyName,
        email: email,
        phone: phone,
      });
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};



export const contactUs = async function(subject,nameSurname,email,phone,description){
  try{
      const resp = await axios.post(apiBaseURL+`/contactUs`,
      {
          subject:subject,
          nameSurname: nameSurname,
          email: email,
          phone: phone,
          description:description
      });
      return resp.data;
  }catch(err){
      console.log(err);
  }
};  

export const search = async function(searctString){
  try{
      const resp = await axios.get(`https://api.totilipotili.com/events/search/${searctString}`,{
      });
      //console.log(resp.data)
      return resp.data;
  }catch(err){
      console.log(err);
  }
};

 