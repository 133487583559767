 
export default function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="main-inner">
          <div> 
            <strong className="primary-title">BİZ</strong>
            <ul className="primary-list">
              <li>
                <a href="/about">Hakkımızda</a>
              </li> 
              <li>
                <a href="/faq">S.S.S</a>
              </li>
            </ul>
            <div className="social-list">
              <a target={'_blank'} rel="noreferrer" href="https://www.instagram.com/totilipotili/">
                <img src="/assets/images/icon/instagram.svg" alt="..." width={35} height={35} loading={'lazy'} />
              </a>
            </div>
          </div>
          <div>
            <strong className="primary-title">
              POLİTİKALAR <br />
              VE BİLDİRİMLER
            </strong> 
            <ul className="primary-list">
              <li>
                <a href="/page/gizlilik-sozlesmesi-kvkk">Gizlilik ve KVKK</a>
              </li>
              <li>
                <a href="/page/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</a>
              </li>
              <li>
                <a href="/page/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</a>
              </li>
              <li>
                <a href="/page/on-bilgilendirme-metni">Ön Bilgilendirme Metni</a>
              </li>
              <li>
                <a href="/page/iptal-iade-degisim-kosullari">İptal, İade ve Değişim Koşulları</a>
              </li>
            </ul>
          </div>
          <div>
            <strong className="primary-title">İŞ ORTAKLARI</strong>
            <ul className="primary-list">
              <li>
                <a href="/partnership">İş Ortağı Ol!</a>
              </li> 
              <li>
                <a target='_blank' rel="noreferrer" href="http://panel.totilipotili.com/">İş Ortağı Girişi</a>
              </li>
            </ul>
          </div>
          <div>
            <strong className="primary-title">İLETİŞİM</strong>
            <ul className="primary-list adress">
              <li><span>Adres:</span> Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL</li>
              <li>
                <a href="mailTo:info@totilipotili.com">info@totilipotili.com</a>
              </li>
              <li>
                <a href="tel:+905458088684">0 545 808 86 84</a>
              </li> 
            </ul>
          </div>

          <img src="/assets/images/icon/footer-circle.svg" className="shape-elem left-top" alt="..." width={68} height={68} loading={'lazy'} />
          <img src="/assets/images/icon/footer-triangle.svg" className="shape-elem top-right" alt="..." width={68} height={72} loading={'lazy'} />
          <img src="/assets/images/icon/footer-half-circle.svg" className="shape-elem bottom-center" alt="..." width={68} height={68} loading={'lazy'} />
        </div>
      </div>
      <div className="bottom-inner-container">
        <div className="container">
          <div className="bottom-inner">
            <div>
              <p>
                <b>Totilipotili 2024</b>&nbsp;&nbsp;All rights reserved.
              </p>
              <p className="viggo">
                <b>Designed by Viggo Agency</b>
              </p>
            </div>
            <div>
              <img src="/assets/images/bank-brands.png" alt="..." width={756} height={53} loading={'lazy'} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
